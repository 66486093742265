import React from 'react'
import styles from '../../../styles/code.module.scss'

const Java = () => {
  return (
    <React.Fragment>
      <pre className={styles.preCode}>
        OkHttpClient client = new OkHttpClient().newBuilder()
      </pre>
      <pre className={styles.preCode}> .build(); </pre>
      <pre className={styles.preCode}>
        MediaType mediaType = MediaType.parse(
        <span>&quot;text/plain&quot;</span>);
      </pre>
      <br />
      <pre className={styles.preCode}>
        RequestBody body = new
        MultipartBody.Builder().setType(MultipartBody.FORM)
      </pre>
      <pre className={styles.preCode}>
        {' '}
        .addFormDataPart(<span>&quot;image&quot;</span>,
        <span>&quot;/C:/Users/Gurminder/Desktop/test_imgs/test.jpg&quot;</span>,
      </pre>
      <pre className={styles.preCode}>
        {' '}
        RequestBody.create(MediaType.parse(
        <span>&quot;application/octet-stream&quot;</span>),
      </pre>
      <pre className={styles.preCode}>
        {' '}
        new File(
        <span>&quot;/C:/Users/Gurminder/Desktop/test_imgs/test.jpg&quot;</span>
        )))
      </pre>
      <pre className={styles.preCode}>
        {' '}
        .addFormDataPart(<span>&quot;api_key&quot;</span>,
        <span>&quot;fXX5d7X5450844b698XX5b9XXd3796&quot;</span>)
      </pre>
      <pre className={styles.preCode}> .build();</pre>
      <br />
      <pre className={styles.preCode}>
        Request request = new Request.Builder()
      </pre>
      <pre className={styles.preCode}>
        {' '}
        .url(
        <span>
          &quot;https://api.alchemyai.net/api/v1/deployment/prediction/28b97f47707d429c/&quot;
        </span>
        )
      </pre>

      <pre className={styles.preCode}>
        {' '}
        .method(<span>&quot;POST&quot;</span>, body)
      </pre>
      <pre className={styles.preCode}>
        {' '}
        .addHeader(<span>&quot;Authorization&quot;</span>,{' '}
        <span>&quot;Token 036a598231f8Xde3XX9002XXebf345XXX787d654&quot;</span>)
      </pre>
      <pre className={styles.preCode}> .build();</pre>
      <br />
      <pre className={styles.preCode}>
        Response response = client.newCall(request).execute();
      </pre>
    </React.Fragment>
  )
}

export default Java
