import React from 'react'
import styles from '../../../styles/code.module.scss'

const Php = () => {
  return (
    <React.Fragment>
      <pre className={styles.preCode}>{'<?php'}</pre>
      <pre className={styles.preCode}>
        require_once <span>&apos;HTTP/Request2.php&apos;</span>;
      </pre>
      <pre className={styles.preCode}>{'$request = new HTTP_Request2();'}</pre>
      <pre className={styles.preCode}>
        $request{' -> '}setUrl(
        <span>
          &apos;https://ai.alchemy.is/api/v1/deployment/prediction/28b97f47707d429c/&apos;
        </span>
        );
      </pre>
      <pre className={styles.preCode}>
        {'$request -> setMethod(HTTP_Request2::METHOD_POST);'}
      </pre>
      <pre className={styles.preCode}>{'$request -> setConfig(array('}</pre>
      <pre className={styles.preCode}>
        {' '}
        <span>&apos;follow_redirects&apos;</span> {'=>'} TRUE
      </pre>
      <pre className={styles.preCode}>{'));'}</pre>
      <pre className={styles.preCode}>
        {'$request -> addPostParameter(array('}
      </pre>
      <pre className={styles.preCode}>
        {' '}
        <span>&apos;api_key&apos;</span> {'=>'}{' '}
        <span>&apos;fXX5d7X5450844b698XX5b9XXd3796&apos;</span>
      </pre>
      <pre className={styles.preCode}>{'));'}</pre>

      <pre className={styles.preCode}>
        $request {'->'} addUpload(<span>&apos;image&apos;</span>,{' '}
        <span>&apos;/C:/Users/user/Desktop/test_imgs/test.jpg&apos;</span>,{' '}
        <span>&apos;/C:/Users/user/Desktop/test_imgs/test.jpg&apos;</span>,{' '}
        <span>&apos;{'<Content-Type Header>'}&apos;</span>);
      </pre>
      <pre className={styles.preCode}>{'try {'}</pre>
      <pre className={styles.preCode}> {'$response = $request -> send();'}</pre>
      <pre className={styles.preCode}>
        {' '}
        {'if ($response -> getStatus() == 200) {'}
      </pre>
      <pre className={styles.preCode}> {'echo $response->getBody();'}</pre>
      <pre className={styles.preCode}> {'}'}</pre>
      <pre className={styles.preCode}> {'else {'}</pre>
      <pre className={styles.preCode}>
        {' '}
        echo <span>&apos;Unexpected HTTP status: &apos;</span> . $response{' '}
        {'->'} getStatus() . <span>&apos; &apos;</span> .
      </pre>
      <pre className={styles.preCode}> {'$response->getReasonPhrase();'}</pre>
      <pre className={styles.preCode}> {'}'}</pre>
      <pre className={styles.preCode}>{'}'}</pre>
      <pre className={styles.preCode}>
        {'catch(HTTP_Request2_Exception $e) {'}
      </pre>
      <pre className={styles.preCode}>
        {' '}
        echo <span>&apos;Error: &apos;</span> . $e {'->'} getMessage();
      </pre>
      <pre className={styles.preCode}>{'}'}</pre>
    </React.Fragment>
  )
}

export default Php
