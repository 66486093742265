import Head from 'next/head'
import { useState, useEffect } from 'react'
import Image from 'next/image'
import { motion } from 'framer-motion'
import styles from '../styles/Home.module.scss'
import Link from 'next/link'
import ChairImage from '../assets/chair.png'
import Section2Image from '../assets/pic.png'
import Section3Image from '../assets/ui.png'
import uploadIcon from '../assets/icon-1.svg'
import trainIcon from '../assets/icon-2.svg'
import deployIcon from '../assets/icon-3.svg'
import terminal from '../assets/textarea_box.svg'
import mobileTerminal from '../assets/mobile_terminal.svg'

import PythonCode from '../components/CodeComponent/Python/Python'
import NodeCode from '../components/CodeComponent/Node/Node'
import JavaCode from '../components/CodeComponent/Java/Java'
import PhpCode from '../components/CodeComponent/Php/Php'
import CurlCode from '../components/CodeComponent/Curl/Curl'

export default function Home() {
  const [code, setcode] = useState('PYTHON')

  const codeboxTabClickHandler = (value) => {
    setcode(value)
  }

  const displayCode = () => {
    if (code === 'PYTHON') {
      return <PythonCode />
    } else if (code === 'NODE') {
      return <NodeCode />
    } else if (code === 'JAVA') {
      return <JavaCode />
    } else if (code === 'PHP') {
      return <PhpCode />
    } else if (code === 'CURL') {
      return <CurlCode />
    }
  }

  return (
    <div className={styles.container}>
      <Head>
        <title>Train & deploy visual AI models easily - AlchemyAI</title>
        <meta charSet=" utf-8" />
        <meta
          name="description"
          content="Build your own Image Recognition AI models - or use our prebuilt ones. Simplified UI
          and fast API. Low-cost."
        />
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          name="viewport"
        />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta property="og:type" content="website" />
        <meta property=" og:image" content="https://alchemyai.net/meta.jpg" />
        <meta
          property="og:title"
          content="AlchemyAI - Train & deploy visual AI models easily"
        />
        <link rel="icon" href="/icons/favicon.ico" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
      </Head>
      <div className={styles.siteRoute}>
        <div className={styles.logoCircle}></div>
        <div className={styles.main}>
          <div className={styles.section1}>
            <div className={styles.textArea}>
              <h1 className={styles.bigTitle}>
                Build your own AI models easily
              </h1>
              <h4 className={styles.subtitle}>
                Amp up your platform with custom-made and affordable image
                recognition.
              </h4>
              <Link
                className={styles.buttonLink}
                href="https://app.alchemyai.net/register"
              >
                <button className={styles.getStartedButton}>GET STARTED</button>
              </Link>
            </div>
            <div className={styles.chairImage}>
              <Image
                src={ChairImage}
                alt="prediction-result"
                objectFit="scale-down"
                height="1100px"
              />
            </div>
          </div>

          <div className={styles.section3}>
            <div className={styles.textArea}>
              <h3 className={styles.smallTitle}>
                Simple yet powerful training studio
              </h3>
              <h4 className={styles.subtitle}>
                A dark and functional design without bloat.
              </h4>
            </div>
            <div className={styles.backgroundCircle}>
              <div className={styles.section3image}>
                <Image
                  src={Section3Image}
                  alt="result"
                  objectFit="scale-down"
                  height="960px"
                />
              </div>
            </div>
          </div>

          <div className={styles.section4}>
            <Activity
              title="UPLOAD"
              subtitle="Create tags and upload images."
            />
            <Activity title="TRAIN" subtitle="Let Alchemy do its magic." />
            <Activity title="DEPLOY" subtitle="Get predictions via API." />
          </div>

          <div className={styles.section2}>
            <div className={styles.imageArea}>
              <Image
                src={Section2Image}
                alt="result"
                objectFit="contain"
                height="550px"
              />
            </div>
            <div className={styles.textArea}>
              <h3 className={styles.smallTitle}>
                Explore ready-made AI models
              </h3>
              <h4 className={styles.subtitle}>
                If you don’t need a niche model, you can use our prebuilt models
                - just send a request to out API. Our AI engine currently
                covers:
                <span> Celebrity AI, Food AI, Skin AI & Design AI</span>. You
                can test them out live below (no signup required).
              </h4>
              <Link
                className={styles.buttonLink}
                href="https://demo.alchemyai.net/"
              >
                <button className={styles.borderedButton}>BROWSE MODELS</button>
              </Link>
            </div>
          </div>

          <div className={styles.section5}>
            <div className={styles.lightBackground} />
            <div className={styles.textArea}>
              <h3 className={styles.centerTitle}>
                Get predictions delivered to your app via fast API
              </h3>
              <h4 className={styles.centerSubtitle}>
                We give you $10 credit for free when you sign up. You can train
                and use unlimited number of AI models.
              </h4>
              <div className={styles.codeArea}>
                <div className={styles.terminal}>
                  <Image src={terminal} alt="terminal" />
                </div>
                <div className={styles.platformsArea}>
                  <p
                    className={
                      code === 'PYTHON'
                        ? styles.selectedPlatform
                        : styles.platform
                    }
                    onClick={() => codeboxTabClickHandler('PYTHON')}
                  >
                    PYTHON
                  </p>
                  <p
                    className={
                      code === 'NODE'
                        ? styles.selectedPlatform
                        : styles.platform
                    }
                    onClick={() => codeboxTabClickHandler('NODE')}
                  >
                    NODE
                  </p>
                  <p
                    className={
                      code === 'JAVA'
                        ? styles.selectedPlatform
                        : styles.platform
                    }
                    onClick={() => codeboxTabClickHandler('JAVA')}
                  >
                    JAVA
                  </p>
                  <p
                    className={
                      code === 'PHP' ? styles.selectedPlatform : styles.platform
                    }
                    onClick={() => codeboxTabClickHandler('PHP')}
                  >
                    PHP
                  </p>
                  <p
                    className={
                      code === 'CURL'
                        ? styles.selectedPlatform
                        : styles.platform
                    }
                    onClick={() => codeboxTabClickHandler('CURL')}
                  >
                    CURL
                  </p>
                </div>
                <div className={styles.codeScripts}>
                  <div className={styles.blankSpace}>{displayCode()}</div>
                </div>
              </div>
              <div className={styles.mobileTerminal}>
                <div className={styles.mobileTerminalImage}>
                  <Image
                    src={mobileTerminal}
                    alt="terminal"
                    objectFit="contain"
                    width={'500px'}
                  />
                </div>
              </div>
              <Link href="https://app.alchemyai.net/register">
                <button className={styles.centerBorderedButton}>
                  GET YOUR API KEY
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Activity = (props) => (
  <div className={styles.activityContainer}>
    <div className={styles.activityImage}>
      <Image
        src={
          props.title === 'UPLOAD'
            ? uploadIcon
            : props.title === 'TRAIN'
            ? trainIcon
            : deployIcon
        }
        alt={props.title}
      />
    </div>
    <h4 className={styles.activityTitle}>{props.title}</h4>
    <p className={styles.activitySubtitle}>{props.subtitle}</p>
  </div>
)
