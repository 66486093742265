import React from 'react'
import styles from '../../../styles/code.module.scss'

const Python = () => {
  return (
    <React.Fragment>
      <pre className={styles.preCode}>import requests</pre>
      <br />
      <pre className={styles.preCode}>
        url =
        <span>
          {' '}
          &quot;https://api.alchemyai.net/api/v1/deployment/prediction/28b97f47707d429c/&quot;{' '}
        </span>
      </pre>
      <br />
      <pre className={styles.preCode}>
        payload= &#123;<span> &#39;api_key&#39;</span> :
        <span> &#39;fXX5d7X5450844b698XX5b9XXd3796&#39; </span> &#125;{' '}
      </pre>
      <pre className={styles.preCode}>files=[</pre>
      <pre className={styles.preCode}>
        {' '}
        (<span>&#39;image&#39;</span>,(<span>&#39;test.jpg&#39;</span>,open(
        <span>&#39;/C:/Users/user/Desktop/test_imgs/test.jpg&#39;</span>,
        <span>&#39;rb&#39;</span>),<span>&#39;image/jpeg&#39;</span>))
      </pre>
      <pre className={styles.preCode}>]</pre>
      <pre className={styles.preCode}>headers = &#123;</pre>
      <pre className={styles.preCode}>
        {' '}
        <span>&#39;Authorization&#39;</span>:{' '}
        <span>&#39;Token 036a598231f8Xde3XX9002XXebf345XXX787d654&#39;</span>
      </pre>
      <pre className={styles.preCode}>&#125;</pre>
      <br />
      <pre className={styles.preCode}>
        response = requests.request(<span>&quot;POST&quot;</span>, url,
        headers=headers, data=payload, files=files)
      </pre>
      <br />
      <pre className={styles.preCode}>print(response.text)</pre>
    </React.Fragment>
  )
}
export default Python
