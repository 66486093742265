import React from 'react'
import styles from '../../../styles/code.module.scss'

const Node = () => {
  return (
    <React.Fragment>
      <pre className={styles.preCode}>var data = new FormData();</pre>
      <pre className={styles.preCode}>
        data.append(<span>&quot;image&quot;</span>, fileInput.files[0],{' '}
        <span>&quot;/C:/Users/user/Desktop/test_imgs/test.jpg&quot;</span>);{' '}
      </pre>
      <pre className={styles.preCode}>
        data.append(<span>&quot;api_key&quot;</span>,{' '}
        <span>&quot;fXX5d7X5450844b698XX5b9XXd3796&quot;</span>);
      </pre>
      <br />
      <pre className={styles.preCode}>var xhr = new XMLHttpRequest();</pre>
      <pre className={styles.preCode}>xhr.withCredentials = true;</pre>
      <br />
      <pre className={styles.preCode}>
        xhr.addEventListener(<span>&quot;readystatechange&quot;</span>,
        function() &#123;
      </pre>
      <pre className={styles.preCode}> if(this.readyState === 4) &#123;</pre>
      <pre className={styles.preCode}> console.log(this.responseText);</pre>
      <pre className={styles.preCode}> &#125;</pre>
      <pre className={styles.preCode}>&#125;);</pre>
      <br />
      <pre className={styles.preCode}>
        xhr.open(<span>&quot;POST&quot;</span>,{' '}
        <span>
          &quot;https://api.alchemyai.net/api/v1/deployment/prediction/28b97f47707d429c/&quot;
        </span>
        );
      </pre>
      <pre className={styles.preCode}>
        xhr.setRequestHeader(<span>&quot;Authorization&quot;</span>,{' '}
        <span>&quot;Token 036a598231f8Xde3XX9002XXebf345XXX787d654&quot;</span>
        );
      </pre>
      <br />
      <pre className={styles.preCode}>xhr.send(data);</pre>
    </React.Fragment>
  )
}

export default Node
