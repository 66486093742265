import React from 'react'
import styles from '../../../styles/code.module.scss'

const Curl = () => {
  return (
    <React.Fragment>
      <pre className={styles.preCode}>
        curl --location --request POST{' '}
        <span>
          &#39;https://api.alchemyai.net/api/v1/deployment/prediction/28b97f47707d429c/&#39;
        </span>{' '}
        \
      </pre>
      <pre className={styles.preCode}>
        --header{' '}
        <span>
          &#39;Authorization: Token
          036a598231f8Xde3XX9002XXebf345XXX787d654&#39;
        </span>{' '}
        \
      </pre>
      <pre className={styles.preCode}>
        --form{' '}
        <span>
          &#39;image=@&quot;/C:/Users/Gurminder/Desktop/test_imgs/test.jpg&quot;
          &#39;
        </span>{' '}
        \
      </pre>
      <pre className={styles.preCode}>
        --form{' '}
        <span>
          &#39;api_key=&quot;fXX5d7X5450844b698XX5b9XXd3796&quot; &#39;
        </span>
      </pre>
    </React.Fragment>
  )
}

export default Curl
